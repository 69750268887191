import { Layout } from 'components/layout'
import { Typography } from 'components/typography'
import { PAGES } from 'constants/routes'
import { useRedirectedUserToLogin } from 'hooks/use-redirected-user-to-login'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { AuthAction, useAuthUser, withAuthUser } from 'next-firebase-auth'
import styled from 'styled-components'
import { urlWithSiteOrigin } from 'utils/url-with-site-origin'

const Heading = styled.h1`
  text-align: center;
  margin: 3rem auto;
`

const Wrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  text-align: center;
`

const AuthWrapper = styled.div`
  min-height: 111px;
`

const FirebaseAuth = dynamic<Record<string, unknown>>(() =>
  import('components/firebase-auth').then((mod) => mod.FirebaseAuth)
)

const Login = () => {
  useRedirectedUserToLogin()

  const authUser = useAuthUser()
  const authLoading = !authUser.clientInitialized

  return (
    <Layout
      authLoading={authLoading}
      fullPageAuthLoader
      seoProps={{
        title: 'Login to TypingMentor and get better at touch typing quickly',
        canonicalUrl: urlWithSiteOrigin(PAGES['/login/']),
        pageUrl: urlWithSiteOrigin(PAGES['/login/']),
      }}
    >
      <Wrapper>
        <Heading>Please sign in!</Heading>
        <AuthWrapper>
          <FirebaseAuth />
        </AuthWrapper>

        <Typography variant="smallText2">
          By continuing, you are indicating that you accept our <Link href="/terms-of-service/">Terms of Service</Link>{' '}
          and <Link href="/privacy">Privacy Policy</Link>.
        </Typography>
      </Wrapper>
    </Layout>
  )
}

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(Login)
