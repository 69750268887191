import { useRouter } from 'next/router'
import { useEffect } from 'react'
import hotToast from 'react-hot-toast'

export const useRedirectedUserToLogin = () => {
  const router = useRouter()
  const fromUrl = router.query.destination
  const toast = router.query.toast

  useEffect(() => {
    if (fromUrl && toast) {
      hotToast(`You were redirected to this page from ${fromUrl}`)
    }
  }, [fromUrl, toast, router, router.query.destination])
}
